import './styles/App.scss';
import Calculadora from './components/Calculadora'

function App() {
  return (
    <div className="App">
      <Calculadora />
    </div >
  );
}

export default App;
